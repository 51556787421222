var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alarm-deal" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "div",
            { staticClass: "form-area table-search" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "demo-form-inline",
                  attrs: {
                    model: _vm.form,
                    "label-position": "left",
                    "label-width": "82px",
                    inline: true,
                    rules: _vm.rules
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间：", prop: "beginTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择开始时间",
                          clearable: false,
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.form.beginTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "beginTime", $$v)
                          },
                          expression: "form.beginTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间：", prop: "endTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "datetime",
                          placeholder: "选择结束时间",
                          clearable: false,
                          "picker-options": _vm.pickerOptions
                        },
                        model: {
                          value: _vm.form.endTime,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "endTime", $$v)
                          },
                          expression: "form.endTime"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "车牌号：" } },
                    [
                      _c("car-tree", {
                        ref: "carTree",
                        attrs: { companyIdList: _vm.choosecph },
                        on: { getData: _vm.getVehIds }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "报警来源：", prop: "alarmSource" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "" },
                          model: {
                            value: _vm.form.alarmSource,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "alarmSource", $$v)
                            },
                            expression: "form.alarmSource"
                          }
                        },
                        _vm._l(_vm.alarmSourceList, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "报警判定：" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.form.alarmConfirm,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "alarmConfirm", $$v)
                            },
                            expression: "form.alarmConfirm"
                          }
                        },
                        _vm._l(_vm.alarmConfirmList, function(item) {
                          return _c("el-option", {
                            key: item.dictCode,
                            attrs: {
                              label: item.dictValue,
                              value: item.dictCode
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { staticClass: "alarm-btn" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.search }
                        },
                        [_vm._v("查询")]
                      ),
                      _vm.$store.state.menu.nowMenuList.indexOf("导出") >= 0
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                size: "small",
                                type: "primary",
                                disabled: _vm.tableData.length === 0,
                                loading: _vm.downLoadStatus
                              },
                              on: { click: _vm.exportData }
                            },
                            [_vm._v("导出")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.tableData,
                height: "calc(100vh - 335px)",
                stripe: ""
              }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "alarmType",
                  label: "报警类型",
                  width: "200",
                  "show-overflow-tooltip": true
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.$store.state.menu.nowMenuList.indexOf("处理") >= 0
                          ? _c(
                              "span",
                              {
                                staticClass: "alarmName",
                                on: {
                                  click: function($event) {
                                    return _vm.showAlarmDetail(scope.row)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.alarmAllObj[scope.row.alarmType])
                                )
                              ]
                            )
                          : _c("span", [
                              _vm._v(
                                _vm._s(_vm.alarmAllObj[scope.row.alarmType]) +
                                  " "
                              )
                            ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "alarmLevel", label: "报警等级" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dictObj.alarmLevelObj[
                                Number(scope.row.alarmLevel)
                              ]
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "cph",
                  label: "车牌号",
                  width: "120",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "companyName",
                  label: "所属车队",
                  width: "160",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "driverName", label: "驾驶员姓名", width: "120" }
              }),
              _c("el-table-column", {
                attrs: { prop: "alarmSource", label: "报警来源" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dictObj.alarmSourceObj[
                                Number(scope.row.alarmSource)
                              ]
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "time",
                  label: "报警时间",
                  "show-overflow-tooltip": true,
                  width: "170"
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("filterAlarmTime")(
                                scope.row,
                                scope.row.time
                              )
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "speedingVelocity",
                  label: "报警最高车速/限速值",
                  width: "180"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "disposeStatus", label: "处理状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.dictObj.disposeStatusObj[
                                Number(scope.row.disposeStatus)
                              ]
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "disposeStatus", label: "报警确认" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.alarmConfirmobj[
                                Number(scope.row.alarmConfirm)
                              ]
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _c("span", [_vm._v("共 " + _vm._s(_vm.total) + " 条")]),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.loading,
                    disabled: !_vm.pageTokenList.length
                  },
                  on: { click: _vm.prePage }
                },
                [_vm._v("上一页")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.loading,
                    disabled: !_vm.nextToken
                  },
                  on: { click: _vm.nextPage }
                },
                [_vm._v("下一页")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "报警详情",
            visible: _vm.dialogVisible,
            width: "70%",
            top: "10vh",
            "custom-class": "alarm-detail"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "body" },
            [
              _vm.dialogVisible
                ? _c("alarm-dialog", {
                    ref: "alarmDia",
                    attrs: {
                      detailData: _vm.alarmDetail,
                      typeObj: _vm.dictObj.typeObj,
                      alarmLevelObj: _vm.dictObj.alarmLevelObj,
                      alarmSourceObj: _vm.dictObj.alarmSourceObj,
                      disposeWayList: _vm.dictList.disposeWayList,
                      alarmConfirmList: _vm.dictList.alarmConfirmList,
                      disposeTypeObj: _vm.dictObj.disposeTypeObj,
                      disposeWayObj: _vm.dictObj.disposeWayObj,
                      isBatch: _vm.isBatch,
                      alarmList: _vm.multipleSelection,
                      alarmAllObj: _vm.alarmAllObj
                    },
                    on: { cancelDialog: _vm.cancelDialog }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              !_vm.fileDialog
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onSaveDetail }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }